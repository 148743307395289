import { BottomSheet } from '@daangn/karrot-clothes'
import { BoxButton } from '@daangn/sprout-components-button'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import CoveredLazyLoadImage from '@src/components/_lib/CoveredLazyLoadImage'
import { appAtom } from '@src/features/_global/state/app'

import NearbyCloseImg from './nearbyClose.png'

const NearbyCloseBottomSheet = () => {
  const { os } = useRecoilValue(appAtom)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const handleCTAClick = () => {
    switch (os) {
      case 'IOS': {
        window.location.href = 'https://apps.apple.com/kr/app/%EB%8B%B9%EA%B7%BC/id1018769995'
        return
      }
      case 'ANDROID':
      default: {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.towneers.www'
        return
      }
    }
  }

  return (
    <BottomSheetStyled open={isOpen} onClose={() => {}}>
      <div style={{ height: '100%' }}>
        <Title>
          <div>‘내 근처’ 서비스가 ‘동네지도’로</div>
          <div>새롭게 달라졌어요</div>
        </Title>
        <Desc>이용하려면 앱 업데이트를 해주세요.</Desc>
        <ImageArea>
          <S_CoveredLazyLoadImage src={NearbyCloseImg} alt="내근처 종료 이미지" visibleByDefault />
        </ImageArea>
        <ButtonArea>
          <BoxButton variant="primary" size="xlarge" onClick={handleCTAClick}>
            앱 업데이트하기
          </BoxButton>
        </ButtonArea>
      </div>
    </BottomSheetStyled>
  )
}

export default NearbyCloseBottomSheet

const BottomSheetStyled = styled(BottomSheet)`
  ul {
    border-radius: 20px 20px 0 0;
    padding: 24px 16px 15px;
  }
`

const Title = styled.div`
  margin-bottom: 6px;
  width: 100%;
  text-align: center;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title2Bold};
`

const Desc = styled.div`
  margin-bottom: 8px;
  text-align: center;
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.subtitle1Regular};
`

const ImageArea = styled.div`
  padding: 16px 0px;
  height: 180px;
  box-sizing: border-box;
`

const ButtonArea = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '12px',
})

const S_CoveredLazyLoadImage = styled(CoveredLazyLoadImage)``
