import styled from '@emotion/styled'
import { type ActivityComponentType } from '@stackflow/react'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { appAtom } from '@src/features/_global/state/app'

import { NEARBY_REMOTE_APP, NEARBY_V2_VERSION_CHECK } from '../../constants'
import { useLogger } from '../../hooks/useLogger'
import { useRemoteWebview } from '../../hooks/useRemoteWebview'
import { satisfyVersion } from '../../lib/version-check'
import ActivityLayout from '../_lib/ActivityLayout'
import ShowPageLog from '../_lib/ShowPageLog'
import HomeNavbarDummy from '../Home/HomeNavbarDummy'
import HomeContentsDummy from '../Home/NearbyClose/HomeContentsDummy'
import HomeDirectoryDummy from '../Home/NearbyClose/HomeDirectoryDummy'
import NearbyCloseBottomSheet from '../Home/NearbyClose/NearbyCloseBottomSheet'

const PageHome: ActivityComponentType = ({ params }) => {
  const { os, version } = useRecoilValue(appAtom)

  const { nearbyLog } = useLogger()

  useRemoteWebview(NEARBY_REMOTE_APP, () => {
    nearbyLog({
      params: {
        name: 'access_local_webview',
        os: os,
        version: version,
      },
    })
  })

  const isNearbyV2 = useMemo(
    () =>
      satisfyVersion({
        os: os,
        version: version,
        androidVersionCheck: NEARBY_V2_VERSION_CHECK.android,
        iosVersionCheck: NEARBY_V2_VERSION_CHECK.ios,
      }),
    [os, version]
  )

  return (
    <ActivityLayout activityName="Home">
      <ShowPageLog name="nearby_event" params={{ name: 'show_onload', type: 'nearby-close', ...params }} />
      <Container>
        {!isNearbyV2 && <HomeNavbarDummy />}

        <Stretched>
          <Main>
            <HomeDirectoryDummy />
            <HomeContentsDummy />
          </Main>
        </Stretched>

        <NearbyCloseBottomSheet />
      </Container>
    </ActivityLayout>
  )
}

export default PageHome

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Stretched = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`

const Main = styled.div`
  overflow: hidden;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
`
