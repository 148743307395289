import { type BridgeRegionInfo, type BridgeUserInfo, type BridgeAppInfo } from '@src/bridgeInfo'
import { type Position } from '@src/currentPositionResource'

/**
 * 스테이지
 */
export const STAGE = import.meta.env.VITE_STAGE as 'production' | 'alpha' | 'development'

export const CLIENT_NAME = (import.meta.env.CLIENT_NAME || 'nearby-client-{STAGE}').replace('{STAGE}', STAGE)
/**
 * Client Version
 */
export const CLINET_VERSION = import.meta.env.CLIENT_VERSION as string
/**
 * 커밋 Hash
 */
export const VERSION = import.meta.env.VERSION as string

export const IS_PRODUCTION = STAGE === 'production'
export const IS_LOCAL = import.meta.env.VITE_IS_LOCAL === 'true'

/**
 * Sentry DSN
 */
export const SENTRY_DSN =
  import.meta.env.VITE_SENTRY_DSN ?? 'https://076f28b961d540bfb03040115245f252@o24217.ingest.sentry.io/5556145'

export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN ?? ''

export const DATADOG_APPLICATION_ID = import.meta.env.VITE_DATADOG_APPLICATION_ID ?? ''

/**
 * Nearby 서비스 (내 근처 서비스) 엔드포인트
 */
export const NEARBY_API_ENDPOINT =
  import.meta.env.VITE_NEARBY_API_ENDPOINT ??
  (IS_PRODUCTION ? 'https://nearby.kr.karrotmarket.com' : 'https://nearby.alpha.kr.karrotmarket.com')

export const LOCAL_GATEWAY_API_ENDPOINT =
  import.meta.env.VITE_LOCAL_GATEWAY_API_ENDPOINT ??
  (IS_PRODUCTION
    ? 'https://business-graphql-gateway.kr.karrotmarket.com'
    : 'https://business-graphql-gateway.alpha.kr.karrotmarket.com')

export const EXPERIMENT_API_DOMAIN = IS_PRODUCTION
  ? 'https://experimentation-platform.kr.karrotmarket.com'
  : 'https://experimentation-platform.alpha.kr.karrotmarket.com'

/**
 * Kotisaari 서비스 (지역광고 서비스) 엔드포인트
 */
export const KOTISAARI_API_ENDPOINT = IS_PRODUCTION
  ? 'https://kotisaari.kr.karrotmarket.com'
  : 'https://kotisaari.alpha.kr.karrotmarket.com'

/**
 * 당근마켓 전체 서비스 (최근이용 서비스) Shortcut API 엔드포인트
 */
export const KARROT_SHORTCUT_API_ENDPOINT = IS_PRODUCTION
  ? 'https://shortcut.kr.karrotmarket.com'
  : 'https://shortcut.alpha.kr.karrotmarket.com'

/**
 * 비즈 어카운트 서비스 엔드포인트
 */
export const BIZ_ACCOUNT_ENDPOINT = IS_PRODUCTION
  ? 'https://ba.kr.karrotmarket.com'
  : 'https://ba.alpha.kr.karrotmarket.com'

/**
 * 비즈 피드 서비스 엔드포인트
 */
export const BIZ_FEED_ENDPOINT = IS_PRODUCTION
  ? 'https://business-feed.kr.karrotmarket.com'
  : 'https://business-feed.alpha.kr.karrotmarket.com'

/**
 * 비즈 소식 서비스 엔드포인트
 */
export const BIZ_POST_ENDPOINT = IS_PRODUCTION
  ? 'https://business-post.kr.karrotmarket.com'
  : 'https://business-post.alpha.kr.karrotmarket.com'

/**
 * 프로퍼티 서비스 엔드포인트
 */
export const X_PROPERTY_ENDPOINT = IS_PRODUCTION
  ? 'https://xproperty.kr.karrotmarket.com'
  : 'https://xproperty.alpha.kr.karrotmarket.com'

/**
 * 내 근처 웹뷰 앱
 */
export const NEARBY_REMOTE_APP = IS_PRODUCTION
  ? 'https://nearby.karrotwebview.com'
  : 'https://nearby.alpha.karrotwebview.com'

/**
 * 웹뷰 도메인
 */
export const LOCAL_WEBVIEW_DOMAIN = IS_PRODUCTION
  ? 'webview.prod.kr.karrotmarket.com'
  : 'webview.alpha.kr.karrotmarket.com'
/**
 * 홈 검색, 내 근처 검색 웹뷰 앱
 */
export const SEARCH_APP = IS_PRODUCTION
  ? 'https://webview.prod.kr.karrotmarket.com/search'
  : 'https://webview.alpha.kr.karrotmarket.com/search'

/**
 * 비즈 프로필 웹뷰 앱
 */
export const BIZ_PLATFORM_APP = IS_PRODUCTION
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

/**
 * 커머스 웹뷰 앱
 */
export const COMMERCE_APP = IS_PRODUCTION
  ? 'https://webview.prod.kr.karrotmarket.com/commerce'
  : 'https://webview.alpha.kr.karrotmarket.com/commerce'

/**
 * 코티사리 웹뷰 앱
 */
export const KOTISAARI_APP = IS_PRODUCTION
  ? 'https://webview.prod.kr.karrotmarket.com/kotisaari'
  : 'https://webview.alpha.kr.karrotmarket.com/kotisaari'

/**
 * 일자리 웹뷰 앱
 */
export const JOBS_APP = IS_PRODUCTION
  ? 'https://webview.prod.kr.karrotmarket.com/jobs'
  : 'https://webview.alpha.kr.karrotmarket.com/jobs'

/**
 * POI 웹뷰 앱
 */
export const POI_APP = IS_PRODUCTION
  ? 'https://webview.prod.kr.karrotmarket.com/poi-webview'
  : 'https://webview.alpha.kr.karrotmarket.com/poi-webview'

/**
 * POI 리모트 웹뷰
 */
export const POI_REMOTE_APP = IS_PRODUCTION
  ? 'https://place.karrotwebview.com/'
  : 'https://place.alpha.karrotwebview.com/'

/**
 * 위젯프로필 웹뷰 앱
 */
export const WIDGET_PROFILE_APP = IS_PRODUCTION
  ? 'https://webview.prod.kr.karrotmarket.com/widget-profile'
  : 'https://webview.alpha.kr.karrotmarket.com/widget-profile'

export const REPORT_REMOTE_APP = IS_PRODUCTION
  ? 'https://report.karrotwebview.com'
  : 'https://report.alpha.karrotwebview.com'

export const BIZ_PROFILE_REMOTE_APP = IS_PRODUCTION
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

export const PERMALINK_DOMAIN = IS_PRODUCTION ? 'https://www.daangn.com' : 'https://alpha.daangn.com'

/**
 * 검색 웹뷰 스킴
 */
export const NEARBY_SEARCH_SCHEME = IS_PRODUCTION ? 'karrot://nearby-search' : 'karrot.alpha://nearby-search'

export const SHARE_DOMAIN = IS_PRODUCTION ? 'https://town.daangn.com/' : ''

export const LOCAL_ELECTION_2022_APP = `karrot${
  IS_PRODUCTION ? '' : '.alpha'
}://minikarrot/router?remote=https%3A%2F%2Felection-2022-local.karrotwebview.com%2Fkarrot%2F%3Futm_source%3Dkarrot%26utm_medium%3Dnearby%26utm_campaign%3Delection-2022-local&navbar=true&scrollable=true`

export const EXPERT_WEBVIEW_DOMAIN = IS_PRODUCTION
  ? 'https://yongdal.karrotwebview.com'
  : 'https://yongdal.alpha.karrotwebview.com'

/**
 * 앱 스킴 Prefix
 */
export const APP_SCHEME = IS_PRODUCTION ? 'karrot://' : 'karrot.alpha://'

export const HOME_TAB_SCHEME = `${APP_SCHEME}tab/home`
export const NEARBY_TAB_SCHEME = `${APP_SCHEME}tab/nearby`

/**
 * 개발용 karrotBridge.getAppInfo() */
export const DEFAULT_APP: BridgeAppInfo | null = import.meta.env.VITE_DEFAULT_USER_AGENT
  ? {
      userAgent: import.meta.env.VITE_DEFAULT_USER_AGENT,
      appHost: '',
      appVersion: 'local',
      appVersionCode: 'local',
      country: 'KR',
      deviceIdentity: 'web',
      locale: 'ko',
    }
  : null

/**
 * 개발용 karrotBridge.getUserInfo()
 */
export const DEFAULT_USER: Partial<BridgeUserInfo> | null = import.meta.env.VITE_DEFAULT_USER_ID
  ? {
      id: import.meta.env.VITE_DEFAULT_USER_ID ? Number(import.meta.env.VITE_DEFAULT_USER_ID) : undefined,
      nickname: import.meta.env.VITE_DEFAULT_USER_NICKNAME ?? undefined,
      authToken: import.meta.env.VITE_DEFAULT_AUTH_TOKEN ?? undefined,
    }
  : null

/**
 * 개발용 karrotBridge.getCurrentPosition()
 */
export const DEFAULT_POSITION: Position | null =
  import.meta.env.VITE_DEFAULT_POSITION_LAT && import.meta.env.VITE_DEFAULT_POSITION_LNG
    ? {
        lat: import.meta.env.VITE_DEFAULT_POSITION_LAT,
        lng: import.meta.env.VITE_DEFAULT_POSITION_LNG,
      }
    : null

/**
 * 개발용 karrotBridge.getRegionInfo()
 */
export const DEFAULT_REGION: Partial<BridgeRegionInfo> | null = import.meta.env.VITE_DEFAULT_REGION_ID
  ? {
      id: import.meta.env.VITE_DEFAULT_REGION_ID ? Number(import.meta.env.VITE_DEFAULT_REGION_ID) : -1,
      name2Id: import.meta.env.VITE_DEFAULT_REGION2_ID ? Number(import.meta.env.VITE_DEFAULT_REGION2_ID) : -1,
      name3Id: import.meta.env.VITE_DEFAULT_REGION_ID ? Number(import.meta.env.VITE_DEFAULT_REGION_ID) : -1,
      name: import.meta.env.VITE_DEFAULT_REGION_NAME ?? undefined,
      centerCoordinates: {
        latitude: Number(DEFAULT_POSITION?.lat) ?? undefined,
        longitude: Number(DEFAULT_POSITION?.lng) ?? undefined,
      },
    }
  : null

/**
 * 안드로이드 앱 업데이트 링크
 */
export const APP_UPDATE_TARGET_URI_FOR_ANDROID = 'https://play.google.com/store/apps/details?id=com.towneers.www'

/**
 * iOS 앱 업데이트 링크
 */
export const APP_UPDATE_TARGET_URI_FOR_IOS = 'https://itunes.apple.com/kr/app/pangyojangteo/id1018769995?l=ko&ls=1&mt=8'

/**
 * 로컬 스토리지에 PersistedState 을 저장할 키
 */
export const LOCAL_STORAGE_STATE_KEY = `nearby/${STAGE}/STATE`

/**
 * 로컬 스토리지에 PersistedState 을 저장할 키
 */
export const LOCAL_STORAGE_DEV_KEY = `nearby/${STAGE}/DEV`

/**
 * 로컬 스토리지에 PersistedState 을 저장할 키
 */
export const SESSION_STORAGE_STATE_KEY = `nearby/${STAGE}/session/STATE`

/**
 * 로컬 스토리지 Spotlight Onboarding상태를 저장할 키
 */
export const LOCAL_STORAGE_SPOTLIGHT_KEY = `nearby/${STAGE}/v1/spotlgiht`

/**
 * 로컬 스토리지에 Fallback App를 저장할 키
 */
export const LOCAL_STORAGE_FALLBACK_APP_KEY = `nearby/${STAGE}/v1/FALLBACK_app`

/**
 * 로컬 스토리지에 Fallback Region를 저장할 키
 */
export const LOCAL_STORAGE_FALLBACK_REGION_KEY = `nearby/${STAGE}/v1/FALLBACK_region`

/**
 * 로컬 스토리지에 Fallback User를 저장할 키
 */
export const LOCAL_STORAGE_FALLBACK_USER_KEY = `nearby/${STAGE}/v1/FALLBACK_user`

/**
 * 로컬 스토리지에 Fallback User를 저장할 키
 */
export const LOCAL_STORAGE_CATEGORY_HOME_FILTER_TOOLTIP_KEY = `nearby/${STAGE}/v1/category_home_filter_tooltip`

/**
 * 로컬 스토리지에 동네지도를 디폴트로 보여줄지 결정하는 키
 */
export const LOCAL_STORAGE_LAND_TO_LOCAL_MAP_KEY = `local-map-local-storage-key/${
  STAGE === 'production' ? 'prod' : 'alpha'
}/is-local-map-default`

/**
 * 네트워크 재시도 숫자
 */
export const RETRY_COUNT = 3

/**
 * 카카오 Developers JavaScript 키
 */
export const KAKAO_JS_KEY = (import.meta.env.VITE_KAKAO_JS_KEY ?? '').split(',')

export const SECTION_ANIMATION_DELAY = 100

export const SECTION_ANIMATION_DISTANCE = 25

export const NAVBAR_DIVIDER_VISIBLE_PERCENT = 0.05

export const DISTANCE_UNIT_LIMIT = 5000
export const MAP_NEARBY_DISTANCE_LIMIT = 2000

export const BOOKMARK_BUTTON_DEBOUNCE_TIME = 300

export const NEARBY_V2_VERSION_CHECK = {
  android: '>=6.5.3 && < 24.8.0',
  ios: '>=6.8.8 && < 24.8.0',
}

export const FAB_WRITE_CLOSE_EVENT = 'nearby-write'
export const FAB_WRITE_CLOSE_EVENT_WRITEPAGE = 'nearby-write-writepage'

export const BIZ_WRITE_NUDGING_TOOLTIP_DELAY = 3000

export const STORE_DEFAULT_IMAGE_URL =
  'https://dnvefa72aowie.cloudfront.net/origin/etc/202205/4904f2842c03482022ce41522e07de3a130a0ad60ec35a23c242935547675154.png'

export const LOCAL_CONTENT_CURATOR_GATEWAY_API = IS_PRODUCTION
  ? 'https://business-graphql-gateway.kr.karrotmarket.com'
  : 'https://business-graphql-gateway.alpha.kr.karrotmarket.com'

export const LOCAL_MAP_URL = IS_PRODUCTION
  ? 'https://local-map.karrotwebview.com/home'
  : 'https://local-map.alpha.karrotwebview.com/home'
