import styled from '@emotion/styled'

import { DirectoryDummy } from './DirectoryDummy'
import PurposeLaunchpadItem from '../Directory/HomePurposeLaunchpad/PurposeLaunchpadItem'
import SectionContainer from '../lib/SectionContainer'

const VIEW_LAUNCHPAD_COLUMNS = 4

const HomeDirectoryDummy = () => {
  return (
    <LaunchpadContainer>
      <LaunchpadGrid>
        {DirectoryDummy.purposeCategories.map((purposeCategory) => (
          <PurposeLaunchpadItem
            key={purposeCategory.id}
            id={purposeCategory.id}
            onClick={() => {}}
            iconUrl={purposeCategory.iconUrl}
            name={purposeCategory.name}
            isNew={purposeCategory.isNew}
          />
        ))}
      </LaunchpadGrid>
    </LaunchpadContainer>
  )
}

export default HomeDirectoryDummy

export const LaunchpadContainer = styled(SectionContainer)`
  transition: padding 0.25s ease-in-out;
  padding: 0.25rem 0 1rem;
`

export const LaunchpadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${VIEW_LAUNCHPAD_COLUMNS}, 1fr);
  grid-gap: 0;
  grid-row-gap: 0;
  grid-column-gap: 0.313rem;
  padding: 0 1rem;
`
