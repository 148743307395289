import styled from '@emotion/styled'
import { IconCameraFill } from '@seed-design/icon'
import { type FC } from 'react'

import { type PropsOf } from '../../../lib/T/utilTypes'
import Spinner from '../../_lib/Spinner'
import HomeCreatePoiOrBizProfile from '../HomeCreatePoiOrBizProfile'
import SectionBottomButton from '../lib/SectionBottomButton'
import SectionContainer, { S_SectionContainer } from '../lib/SectionContainer'
import SectionTitle from '../lib/SectionTitle/SectionTitle'

const HomeContentsDummy = () => {
  return (
    <div>
      <SectionLoading
        SectionTitleProps={{
          title: '우리 동네 이웃들의 스토리',
          isNew: true,
          rightButton: {
            content: (
              <AddShortVideo>
                <IconCameraFill width={16} height={16} /> 스토리 올리기
              </AddShortVideo>
            ),
          },
        }}
        height={'240px'}
      />
      <HomeCreatePoiOrBizProfile />
      <SectionLoading
        SectionTitleProps={{
          title: '우리 동네 인기 쿠폰',
          rightButton: {
            content: '받은 쿠폰함',
          },
        }}
        height={'200px'}
      />
    </div>
  )
}

const AddShortVideo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export default HomeContentsDummy

interface SectionLoadingProps {
  SectionTitleProps: PropsOf<typeof SectionTitle>
  spinnerSize?: number
  height: string
}

const SectionLoading: FC<SectionLoadingProps> = ({ SectionTitleProps, height }) => {
  return (
    <SectionContainer>
      <SectionTitle {...SectionTitleProps} />
      <FallbackContainer height={height}>
        <Spinner size={24} />
      </FallbackContainer>
      <SectionBottomButton content="더보기" />
    </SectionContainer>
  )
}

const FallbackContainer = styled(S_SectionContainer)<{ height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  padding-top: 0.75rem;
  margin-bottom: 0.5rem;
`
