export const DirectoryDummy = {
  __typename: 'SectionPurposeLaunchpad',
  purposeCategories: [
    {
      externalId: 77,
      id: 'PurposeCategory#77',
      name: '운동',
      targetUri:
        'karrot://minikarrot/router?remote=https://yongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F5%3Freferrer%3Dnearby_directory',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/b7c424b4-c5a1-4f34-b08d-3d611f5d34a9.png',
      isVerticalService: false,
    },
    {
      externalId: 30,
      id: 'PurposeCategory#30',
      name: '취미·클래스',
      targetUri:
        'karrot://minikarrot/router?remote=https://yongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F6%3Freferrer%3Dnearby_directory',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/d15cd12d-2fce-4241-8cd0-ed04209ac230.png',
      isVerticalService: false,
    },
    {
      externalId: 109,
      id: 'PurposeCategory#109',
      name: '미용실',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F209%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/73bdf342-b19e-4ee1-8f0a-83e41a30ac54.png',
      isVerticalService: false,
    },
    {
      externalId: 22,
      id: 'PurposeCategory#22',
      name: '뷰티샵',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F123%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/4c565cb0-30d8-4fb9-b307-9d6ef4c861d2.png',
      isVerticalService: false,
    },
    {
      externalId: 56,
      id: 'PurposeCategory#56',
      name: '이사·용달',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fyongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F%3Ftitle%3D%EC%9D%B4%EC%82%AC%E3%83%BB%EC%9A%A9%EB%8B%AC%26serializedServiceFields%3D%5B%221%22%2C%2214%22%2C%2215%22%5D%26referrer%3Dnearby_directory',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/4a331f44-cdbb-41f0-b970-5e49c94fe6cf.png',
      isVerticalService: false,
    },
    {
      externalId: 59,
      id: 'PurposeCategory#59',
      name: '청소',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fyongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F%3Ftitle%3D%EC%B2%AD%EC%86%8C%26serializedServiceFields%3D%5B%227%22%2C%2216%22%2C%2217%22%2C%2218%22%2C%2219%22%2C%2220%22%2C%2221%22%5D%26referrer%3Dnearby_directory',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/5b192bba-a77a-472e-9d10-f29d9c94e319.png',
      isVerticalService: false,
    },
    {
      externalId: 131,
      id: 'PurposeCategory#131',
      name: '인테리어시공',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fyongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F%3Ftitle%3D%EC%9D%B8%ED%85%8C%EB%A6%AC%EC%96%B4+%EC%8B%9C%EA%B3%B5%26serializedServiceFields%3D%5B%2234%22%2C%2235%22%2C%2236%22%2C%2237%22%2C%2238%22%2C%2239%22%2C%2240%22%2C%2241%22%2C%2242%22%2C%2243%22%2C%2244%22%5D%26referrer%3Dnearby_directory',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/5009c15c-7641-4e0b-a8b8-1c3df570c538.png',
      isVerticalService: false,
    },
    {
      externalId: 73,
      id: 'PurposeCategory#73',
      name: '수리·설치',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fyongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F%3Ftitle%3D%EC%88%98%EB%A6%AC%E3%83%BB%EC%84%A4%EC%B9%98%26serializedServiceFields%3D%5B%226%22%2C%228%22%2C%2222%22%2C%2223%22%2C%2224%22%2C%2225%22%2C%2226%22%2C%2227%22%2C%2228%22%2C%2229%22%2C%2230%22%2C%2231%22%2C%2232%22%2C%2233%22%5D%26referrer%3Dnearby_directory',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/c3955dbb-9e18-47f0-8272-343c4a0a0074.png',
      isVerticalService: false,
    },
    {
      externalId: 149,
      id: 'PurposeCategory#149',
      name: '동네쿠폰',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcoupon_map?referrer=nearby-directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/d4488110-74cf-4ea7-8694-fca2e9ec5063.png',
      isVerticalService: false,
    },
    {
      externalId: 5,
      id: 'PurposeCategory#5',
      name: '음식점',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F106%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/ec4ae85e-0758-4405-a28b-45a807cb9de0.png',
      isVerticalService: false,
    },
    {
      externalId: 17,
      id: 'PurposeCategory#17',
      name: '카페·디저트',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F118%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/80beeb20-623a-4354-a37d-7293717b749f.png',
      isVerticalService: false,
    },
    {
      externalId: 4,
      id: 'PurposeCategory#4',
      name: '농수산물',
      targetUri:
        'karrot://minikarrot/router?remote=https://commerce.karrotwebview.com/produce?referrer=nearby.vertical&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/92e9e54b-63d6-4e89-ae5e-fa8786a9dbd8.png',
      isVerticalService: false,
    },
    {
      externalId: 65,
      id: 'PurposeCategory#65',
      name: '학원·과외',
      targetUri:
        'karrot://minikarrot/router?remote=https://yongdal.karrotwebview.com%2Fexpert%2Fhome%2FserviceDirectory%2F7%3Freferrer%3Dnearby',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/354af58f-c459-493d-9bfd-a288c8cd8b53.png',
      isVerticalService: false,
    },
    {
      externalId: 85,
      id: 'PurposeCategory#85',
      name: '육아',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F189%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/b71f81a5-8884-4fb0-a1db-5bd05f8e10aa.png',
      isVerticalService: false,
    },
    {
      externalId: 40,
      id: 'PurposeCategory#40',
      name: '병원·약국',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F140%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/26c4ecd8-ca14-429c-a85c-18019a864322.png',
      isVerticalService: false,
    },
    {
      externalId: 88,
      id: 'PurposeCategory#88',
      name: '반려동물',
      targetUri:
        'karrot://minikarrot/router?remote=https%3A%2F%2Fnearby.karrotwebview.com%2Fcategory_home%2F192%3Freferrer%3Dnearby_directory&navbar=false&scrollable=false',
      isNew: false,
      app: null,
      iconUrl: 'https://d17jz7czevzf2p.cloudfront.net/350a7fe2-40de-44d9-b59d-62a248f02417.png',
      isVerticalService: false,
    },
  ],
}
